import React from "react";
import {
  List,
  TextField,
  Datagrid,
  RichTextField,
  ShowButton,
  ReferenceField,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  FunctionField,
  Filter,
  SearchInput,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  TextInput,
  Button,
  BulkExportButton,
  BulkDeleteButton,
  Pagination,
  downloadCSV
} from 'react-admin';
import { useSelector } from 'react-redux';
import get from "lodash/get";
import ResetViewsButton from '../shared/ResetViewsButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';

var moment = require('moment'); // require
var LOGIN_LOG_TYPE = "Login";

const exporter = logs => {
    const logsForExport = logs.map(log => {
        let tmpLog = {
          "Event": log.log_title,
          "First Name": log.member_profile.first_name, 
          "Last Name": log.member_profile.last_name,
          "Date": moment(log.created_at).format('MMMM Do YYYY, h:mm:ss a')
        }
        return tmpLog;
    });

    jsonExport(logsForExport, {
        headers: ['Event', 'First Name', 'Last Name', 'Date'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'logs_' + moment().format()); // download as 'logs_date.csv` file
    });
}

const getLogType = log => {
  return log.ballot ? log.ballot.title : log.annual_meeting ? log.annual_meeting.title : LOGIN_LOG_TYPE
}

const useStyles = makeStyles((theme) => ({
    logRow: {
      fontSize: '15pt',
    },
    dateColumn: {
      fontSize: '13pt',
    },
    chip: {
        marginBottom: theme.spacing(1),
    },
    formInput: {
      fontSize: '10pt',
    }
}));


const FullNameField = ({ record = {} }) => <span>{record.first_name} {record.last_name}</span>;

const LogPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} {...props} />;


// const QuickFilter = ({ label }) => {
//     // const translate = useTranslate();
//     const classes = useStyles();
//     return <Chip className={classes.chip} label={label} />;
// };

// <SearchInput source="q" alwaysOn />
        // <QuickFilter source="member" label="Member" />

const LogFilter = props => {
    const classes = useStyles();
    return (
      <Filter {...props}>
          <TextInput label="Search" source="search" alwaysOn />
          <ReferenceInput label="Filter by Ballot" source="ballot_id" reference="ballots" className={classes.formInput}>
              <SelectInput optionText="title" emptyText="No Filter"  />
          </ReferenceInput>
          <ReferenceInput label="Filter by Annual Meeting" source="annual_meeting_id" reference="annual_meetings" className={classes.formInput}>
              <SelectInput optionText="title" emptyText="No Filter"  />
          </ReferenceInput>
          <ReferenceInput label="Filter by Member" source="member_profile_id" reference="member_profiles" allowEmpty>
              <SelectInput optionText="last_name" emptyText="No Filter" />
          </ReferenceInput>
      </Filter>
    );
};

const LogBulkActionButtons = props => (
    <React.Fragment>
        <ResetViewsButton label="Cancel" {...props} />
        <BulkExportButton {...props}/>
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
    </React.Fragment>
);

const LogList = (props) => {
  const classes = useStyles();
  const organization = useSelector((state) => state.authData.organization);
  return (
    <List
      exporter={exporter}
      filter={{ organization_id: organization.id}}
      filters={<LogFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      bulkActionButtons={<LogBulkActionButtons/>}
      pagination={<LogPagination />}
      {...props}
    >
      <Datagrid>
        <TextField source="log_title" sortBy="ballot__title" />
        <ReferenceField label="Member" source="member_profile_id" reference="member_profiles" link={false}>
          <FullNameField source="name" className={classes.logRow} />
        </ReferenceField>
        <DateField source="created_at" showTime label="Date" className={classes.dateColumn} />
      </Datagrid>

    </List>
  )
}

export default LogList