import React from "react";
import jsonExport from 'jsonexport/dist';
import DataProvider from '../DataProvider';
import {myHttpClient} from '../App';
import { connect } from 'react-redux';
import {
  downloadCSV,
  Button,
  showNotification
} from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSelector } from 'react-redux';
var moment = require('moment'); // require

const exportVotes = (props, e) => {
  e.stopPropagation();
  let ballot = props.record;
  const dataProvider = DataProvider('/api', myHttpClient);
  let voteCount = null;
  dataProvider.getManyReference("votes", {
					 sort: {field: "id", order: "ASC"},
					 filter: {ballot_id: ballot.id},
					 pagination: { page: 1, perPage: 9999999 },
				 }).then(({ data }) => {
				 	console.log('data votes', data);
				 	voteCount = data.length;
				   	createAndDownloadCSV(data)
				 }).catch(error => {
				 	console.log(voteCount)
				 	if (voteCount === 0) {
				 		props.showNotification("Nobody has voted yet. Try again later.");
				 	} else {
						props.showNotification("Something went wrong when exporting!");
				 	}
				 });
}

const aggregatedVotes = (votes) => {
	let finalVotes = {}
	votes.forEach(function (vote) {
		let ballotQuestion = vote.ballot_question.title;
		let response = vote.ballot_response.title;
		
		if (!(ballotQuestion in finalVotes)) {
			finalVotes[ballotQuestion] = {}
		} 

		// now save responses
		if (response in finalVotes[ballotQuestion]) {
			finalVotes[ballotQuestion][response] += 1
		} else {
			finalVotes[ballotQuestion][response] = 1
		}
	})
	return finalVotes
}

const createAndDownloadCSV = (votes) => {
	const ballot_name = votes[0].ballot_vote.ballot.title;
	const organization_name = votes[0].ballot_vote.organization.name;
	const ballotForExport = votes.map(vote => {
		return {
		  "Topic": vote.ballot_question.title,
		  "Member First Name": vote.ballot_vote.member_profile.first_name, 
		  "Member Last Name": vote.ballot_vote.member_profile.last_name, 
		  "Selected": vote.ballot_response.title,
		  "Date Voted": moment(vote.created_at).format('MMMM Do YYYY, h:mm:ss a')
		}
	});

	jsonExport(ballotForExport, {
		headers: ['Topic', 'Member First Name', 'Member Last Name', 'Selected', 'Date Voted'] // order fields in the export
	}, (err, csv) => {
		downloadCSV(csv, ballot_name + "_individual_votes_" + moment().format()); // download as 'logs_date.csv` file
	});

	let aggregatedVoteDict = aggregatedVotes(votes);
	let aggregatedRows = []
	for (const [ballotQuestionTitle, votesDict] of Object.entries(aggregatedVoteDict)) {
		// add ballot question row 
		let tmpTitle = {"Name": ballotQuestionTitle, "Vote Count": ""}
		aggregatedRows.push(tmpTitle)

		// now add all the responses in separate rows
		for (const [ballotResponseTitle, voteCount] of Object.entries(votesDict)) {
			let tmpRow = {
				"Name": ballotResponseTitle,
				"Vote Count": voteCount
			};
			aggregatedRows.push(tmpRow)
		}
		aggregatedRows.push({"Name": "", "Vote Count": ""}) //new line
	}

	jsonExport(aggregatedRows, {
		headers: ['Name', 'Vote Count']
	}, (err, csv) => {
		downloadCSV(csv, ballot_name + "_aggregated_votes_" + moment().format()); // download as 'logs_date.csv` file
	});
}

const ExportVotesButton = (props) => {
	const permissions = useSelector((state) => state.authData.permissions);
	if (permissions && permissions.includes("admin")) {
	  return (
		<Button label="Export" onClick={(e) => exportVotes(props, e)}> 
		  <GetAppIcon />
		</Button>
	  )
	} else {
		return <div></div>
	}
}

export default connect(null, {showNotification})(ExportVotesButton)