import React from "react";
import {
  List,
  TextField,
  Datagrid,
  RichTextField,
  ShowButton,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  FunctionField,
  Filter,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  TextInput,
  BooleanField,
  BulkDeleteButton,
  Button,
  EditButton,
  Pagination,
  BooleanInput,
  NullableBooleanInput,
} from 'react-admin';
import {useSelector} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    commentRow: {
      fontSize: '15pt',
    },
    answerBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '100px',
      minWidth: '100px',
      maxWidth: '90%',
      padding: '20px',
      marginLeft: '50px',
      backgroundColor: '#DCDCDC',
      borderRadius: '16px',
    },
    formInput: {
      fontSize: '10pt',
    }, 
    smallText: {
      fontSize: '10pt',
      marginTop: '10px',
      marginLeft: '50px',
    }
}));

const CommentFilter = (props) => {
  const permissions = useSelector((state) => state.authData.permissions);
  const isAdmin = permissions && permissions.includes('admin');

  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput label="Search" source="search" alwaysOn />

      {
        !isAdmin ? null : <NullableBooleanInput 
                              label="Public"
                              source="public_and_member"
                              alwaysOn
                          />
      }
      <ReferenceInput label="Election Comments" source="ballot_id" reference="ballots" className={classes.formInput}>
          <SelectInput optionText="title" emptyText="No Filter"  />
      </ReferenceInput>
      <ReferenceInput label="Annual Meeting Comments" source="annual_meeting_id" reference="annual_meetings" className={classes.formInput}>
          <SelectInput optionText="title" emptyText="No Filter"  />
      </ReferenceInput>
      <ReferenceInput label="Member Comments" source="member_profile_id" reference="member_profiles" allowEmpty>
          <SelectInput optionText="last_name" emptyText="No Filter" />
      </ReferenceInput>
    </Filter>
  );
};

const CommentPanel = ({ id, record, resource }) => {
  const classes = useStyles();
  let answer = record.answer;
  if (!answer) {
    answer = 'This comment does not have a response.'
  }
  return (
    <div>
      <div className={classes.smallText}>Admin Response:</div>
      <div className={classes.answerBox}>
        <Typography variant='subtitle1'>{answer}</Typography>
      </div>
    </div>
  );
}

const CommentBulkActionButtons = props => {
    return (
      <React.Fragment>
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
      </React.Fragment>
    );
};


const CommentPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} {...props} />;

const CommentList = (props) => {
  const classes = useStyles();
  const permissions = useSelector((state) => state.authData.permissions);
  const isAdmin = permissions && permissions.includes('admin');
  const orgID = useSelector((state) => state.authData.organization.id);

  return (
    <List
      exporter={false}
      sort={isAdmin ? { field: "created_at", order: "DESC" } : { field: "priority", order: "ASC" }}
      filters={<CommentFilter />}
      // bulkActionButtons={<CommentBulkActionButtons />}
      filter={{ organization_id: orgID}}
      bulkActionButtons={!isAdmin ? false : <CommentBulkActionButtons />}
      filterDefaultValues={isAdmin ? null : { public_and_member: true }}
      pagination={<CommentPagination />}
      {...props}
    >
      <Datagrid expand={<CommentPanel />} rowClick="expand">
        <TextField source="question" label='Comments (click to see response)' />
        <ReferenceField label="Member" source="member_profile_id" reference="member_profiles" link={false}>
          <TextField source="name" />
        </ReferenceField>
        {
          isAdmin ? <BooleanField source="public" label='Public' /> : null
        }
        {
          isAdmin ? <EditButton /> : null
        }
      </Datagrid>

    </List>
  )
}

export default CommentList