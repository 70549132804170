import React from "react";
import DataProvider from '../DataProvider';
import {myHttpClient} from '../App';
import { connect } from 'react-redux';
import {
  Button,
  showNotification
} from 'react-admin';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSelector } from 'react-redux';


const DeleteVotesButton = (props) => {
  const permissions = useSelector((state) => state.authData.permissions);
  if (permissions && permissions.includes("admin")) {
    return (
      <Button label="" onClick={(e) => DeleteVotes(props, e)}> 
        <DeleteForeverIcon />
      </Button>
    )
  }
  return <div></div>
}

const DeleteVotes = (props, e) => {
    e.stopPropagation();
    const dataProvider = DataProvider('/api', myHttpClient);
    dataProvider.deleteVotes("votes", {
             id: 1,
           }).then(({ data }) => {
             props.showNotification("Deleted votes! Now refresh the page.");
           }).catch(error => {
             props.showNotification("Something went wrong when deleting!");
           });
}

export default connect(null, {showNotification})(DeleteVotesButton)