// import decodeJwt from 'jwt-decode';
import { apiUrl, removeCSRFToken, setCSRFToken, getCSRFToken } from './shared/api';

const authProvider = {
    // login: ({ username, password }) =>  {
    //     const request = new Request(apiUrl() + '/auth-token/', {
    //         method: 'POST',
    //         body: JSON.stringify({ username, password }),
    //         headers: new Headers({ 'Content-Type': 'application/json' }),
    //     });
    //     return fetch(request)
    //         .then(response => {
    //             if (response.status < 200 || response.status >= 300) {
    //                 throw new Error(response.statusText);
    //             }
    //             return response.json();
    //         })
    //         .then(({ token }) => {
    //             // const getCurrentUser = new Request('http://localhost:8000/users/current_user', {
    //             //     method: 'GET',
    //             //     headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Token ${token}`}),
    //             // });
    //             // return fetch(getCurrentUser)
    //             //     .then(response => {
    //             //         if (response.status < 200 || response.status >= 300) {
    //             //             throw new Error(response.statusText);
    //             //         }
    //             //         console.log("Curr user", response.json());
    //             //         // return response.json();
    //             //     })
    //             // if (token) {
    //             //     console.log("**********INSIDE", token);
    //             //   const decodedToken = decodeJwt(token, { header: true });
    //             //   console.log("**********INSIDE DECODE", decodedToken);
    //             //   localStorage.setItem('permissions', decodedToken.permissions);
    //             // }
    //             console.log("***********", token)
    //             localStorage.setItem('is_admin', true);
    //             setCSRFToken(token);
    //         });
    // },
    logout: () => {
        console.log("LOGGING OUT!!!!");
        removeCSRFToken();
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            removeCSRFToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => getCSRFToken()
        ? Promise.resolve()
        : Promise.reject(),

    getPermissions: () => {
        // i have rebuilt the permissions in shared/api.js
        return localStorage.getItem('permissions') ? Promise.resolve() : Promise.reject();
    }
};

export default authProvider;