import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  Button,
  UrlField,
  ReferenceManyField,
  ChipField,
  SingleFieldList,
  SimpleShowLayout,
  Datagrid
} from 'react-admin';


const Organization = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="support_phone" />
        <TextField source="support_email" />
        <ReferenceManyField label="Members" reference="members" target="memberships">
          <Datagrid>
              <TextField source="first_name" />
              <TextField source="last_name" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};



export default Organization