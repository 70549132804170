import React from "react";
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  ReferenceManyField,
  ReferenceField,
  TextField,
  required,
  useDataProvider, Loading, Error,
  RichTextField,
  RadioButtonGroupInput,
  List,
  Toolbar,
  useNotify,
  useRedirect,
  SaveButton,
  Datagrid,
  useQuery
} from 'react-admin';
import { useStore, useDispatch, useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    titleClass: {
      fontSize: '20pt',
      fontWeight: '600',
      margin: '16px'
    },
    formWidth: {
      width: '50%',
      lineHeight: '28px'
    }
});


const SubmitCommentToolbar = props => {
  return <Toolbar {...props} >
    <SaveButton
        label="Submit Comment"
        submitOnEnter={false}
        color="secondary"
    />
  </Toolbar>
};



const CommentCreate = (props) => {
  const classes = useStyles();

  let annualMeetingID = props && props.annualMeetingID;
  let memberProfileID = useSelector((state) => state.authData.id);
  let orgID = useSelector((state) => state.authData.organization.id);
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const onSuccess = ({ data }) => {
      notify('Your comment has been sent to the admins.')
      redirect('/comments');
  };

  return (
      <Create {...props} title="Message the admin" onSuccess={onSuccess}>
          <SimpleForm defaultValue={{organization_id: orgID, member_profile_id: memberProfileID, annual_meeting_id: annualMeetingID}} toolbar={<SubmitCommentToolbar />}>
            <TextInput source="question" multiline className={classes.formWidth} label="Message the admin" validate={[required()]} />
          </SimpleForm>
      </Create>
  )
};
export default CommentCreate