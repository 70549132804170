import React from "react";
import {
  List,
  TextField,
  Datagrid,
  RichTextField,
  ShowButton,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  FunctionField,
  Filter,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  ReferenceManyField,
  TextInput,
  DateField,
  Button,
  BooleanField,
  BulkExportButton,
  Pagination,
  downloadCSV,
  Loading, Error, useQuery,
  showNotification
} from 'react-admin';
import { useSelector } from 'react-redux';
import FetchAlreadyVotedBallotIds from '../shared/fetchAlreadyVoted';
import ResetViewsButton from '../shared/ResetViewsButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import ExportVotesButton from './ExportButton';
import DeleteVotesButton from './DeleteVotesButton';

const useStyles = makeStyles((theme) => ({
    ballotRow: {
      fontSize: '15pt',
    }
}));

function ballotRowClick(id, basePath, record, alreadyVotedBallotIds) {
  if (alreadyVotedBallotIds && Array.isArray(alreadyVotedBallotIds) && alreadyVotedBallotIds.includes(record.id)) {
    return "show"
  }
  return `/votes/create?basePath=${basePath}&ballot_id=${record.id}`;
}

const BallotPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} {...props} />;


const BallotList = (props) => {
  const classes = useStyles();

  const votedForBallotResponseIDs = useSelector((state) => state.authData.voted_on_ids.ballot_response_ids);
  const votedForBallotIDs = useSelector((state) => state.authData.voted_on_ids.ballot_ids);
  const memberProfileID = useSelector((state) => state.authData.id);
  const permissions = useSelector((state) => state.authData.permissions);
  const orgID = useSelector((state) => state.authData.organization.id);

  return (
    <List
      exporter={false}
      bulkActionButtons={false}
      filter={{ organization_id: orgID, ballot_type__slug: props && props.location && props.location.hash ? props.location.hash.substr(1) : null }}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<BallotPagination />}
      {...props}
    >
      <Datagrid
        isRowSelectable={(record) => false}
        rowClick={(id, basePath, record) => ballotRowClick(id, basePath, record, votedForBallotIDs)}
      >
        <TextField source="title" className={classes.ballotRow} />
        <ReferenceManyField filter={{ member_profile_id: memberProfileID }} reference="ballot_votes" target="ballot_id" label="Date Voted">
          <SingleFieldList linkType={false}>
            <DateField source="created_at" showTime className={classes.ballotRow} />
          </SingleFieldList>
        </ReferenceManyField>
      </Datagrid>
    </List>
  )
}

export default BallotList