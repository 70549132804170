import React from "react";
import {
  List,
  TextField,
  Datagrid,
  RichTextField,
  ShowButton,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  FunctionField,
  Filter,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  ReferenceManyField,
  TextInput,
  DateField,
  Button,
  BooleanField,
  BulkExportButton,
  Pagination,
  downloadCSV,
  Loading, Error, useQuery,
  showNotification
} from 'react-admin';
import { useSelector } from 'react-redux';
import FetchAlreadyVotedBallotIds from '../shared/fetchAlreadyVoted';
import ResetViewsButton from '../shared/ResetViewsButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import ExportVotesButton from './ExportButton';
import DeleteVotesButton from './DeleteVotesButton';

const useStyles = makeStyles((theme) => ({
    ballotRow: {
      fontSize: '15pt',
    }
}));


const ExportVotesPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} {...props} />;


const ExportVotesList = (props) => {
  const classes = useStyles();

  const memberProfileID = useSelector((state) => state.authData.id);
  const permissions = useSelector((state) => state.authData.permissions);
  const orgID = useSelector((state) => state.authData.organization.id);
  const isAdmin = permissions && permissions.includes('admin');

  return (
    isAdmin ? 
      <List
        exporter={false}
        bulkActionButtons={false}
        filter={{ organization_id: orgID }}
        sort={{ field: 'created_at', order: 'DESC' }}
        pagination={<ExportVotesPagination />}
        {...props}
      >
        <Datagrid
          isRowSelectable={(record) => false}
          rowClick={null}
        >
          <TextField source="title" className={classes.ballotRow} />
          <DateField source="created_at" showTime className={classes.ballotRow} />
          <ExportVotesButton />
        </Datagrid>
      </List>
    : null
  )
}

//<DeleteVotesButton />

export default ExportVotesList