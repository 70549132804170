import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { stylesReceived } from './styleReducer';
import { connect } from 'react-redux';
import DataProvider from './DataProvider';
import {myHttpClient} from './App';

const overrides = {
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            text: { // Name of the rule
                color: 'white', // Some CSS
            },
        },
        MuiButtonBase: { // override the styles of all instances of this component
            root: { // Name of the rule
                fontSize: 15,
            },
        },
        "MuiSvgIcon": {
            root: {
                width: 30
            }
        },
        RaSidebar: {
            drawerPaper: {
                width: 260
            }
        },
        RaToolbar: {
            mobileToolbar: {
                position: 'relative',
            }
        },
        MuiFormLabel: {
            root: {
                width: '90%',
                fontSize: 14,
            }
        }
    },
}


const DEFAULT_STYLES = {
    palette: {
        primary: grey,
        secondary: grey,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontSize: 19,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    ...overrides,
}

export default function CustomTheme(dbStyles) {
    if (dbStyles && dbStyles.primary_light) {
        return (
            createMuiTheme({
                palette: {
                    primary: {
                        light: dbStyles.primary_light,
                        main: dbStyles.primary_main,
                        dark: dbStyles.primary_dark,
                        contrastText: dbStyles.primary_contrast_text,
                    },
                    secondary: {
                        light: dbStyles.secondary_light,
                        main: dbStyles.secondary_main,
                        dark: dbStyles.secondary_dark,
                        contrastText: dbStyles.secondary_contrast_text,
                    },
                    error: red,
                    contrastThreshold: 3,
                    tonalOffset: 0.2,
                },
                typography: {
                    fontSize: 19,
                    fontFamily: dbStyles.font_family,
                },
                ...overrides,
            })
        )
    }
    return createMuiTheme(DEFAULT_STYLES);
};
