import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  RichTextField,
  ReferenceManyField,
  Datagrid,
  SingleFieldList,
  RadioButtonGroupInput,
  ReferenceField,
  CheckboxGroupInput,
  Loading,
  useDataProvider, Error,
} from 'react-admin';
import { useStore, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import { FetchAlreadyVotedBallotResponseIds } from '../shared/fetchAlreadyVoted';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    titleClass: {
      fontSize: '30pt',
      textAlign: 'center',
      fontWeight: '600',
    },
    bodyClass: {
      fontSize: '17pt',
    },
    title: {
      fontSize: '24pt',
      fontWeight: '600',
    },
    avatar: {
      width: '400px',
    },
    voteResponses: {
      width: '100%',
    },
    voteChoiceCardContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
    },
    votedForStyle: {
      border: '3px solid gold',
    },
    voteChoiceCard: {
      margin: '1rem',
      width: '90%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: '10px',
    },
    voteChoiceCardMedia: {
      display: '',
    },
    primaryImage: {
      width: '80px',
      height: '80px',
      'border-radius': '50%',
      'object-fit': 'cover',
    },
    cardStyle: {
      width: "100%",
      minHeight: 300,
      margin: '0.5em',
      fontSize: '17pt',
    },
    nestedCardStyle: {
      width: "100%",
      minHeight: 75,
      margin: '0.5em',
      display: 'block',
      verticalAlign: 'top'
    },
    VoteResponses: {
      width: "55vw",
    },
    gridRoot: {
      flexGrow: 1,
      overflow: 'hidden',
      padding: theme.spacing(0, 3),
    },
    paper: {
      maxWidth: 400,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
    },
    richTextField: {
      paddingLeft: '18px',
      paddingBottom: '18px',
    },
    expansionTitle: {
      display: 'flex',
      alignItems: 'center'
    }, 
    paddingLeft: {
      paddingLeft: '1em'
    }
}));




const iconQuestionSlugMapping = {
  'candidate-election-single-select': <PermIdentityOutlinedIcon fontSize="large" />
}

const ConditionalBallotQuestionAvatar = (props) => {
  const { record, basePath } = props;
  return (props && props.record && props.record.slug in iconQuestionSlugMapping) ? iconQuestionSlugMapping[props.record.slug] : <AssignmentIcon fontSize="large"/>
}


const AvatarField = (props) => {
  const { id, data, basePath } = props;
  if(id && data && basePath){
    const record = data[id];
    return (
      <ReferenceField id={id} linkType={false} data={data} basePath={basePath} record={record} reference="ballot_question_types" source="ballot_question_type_id">
        <ConditionalBallotQuestionAvatar />
      </ReferenceField>
    )
  }
}

const SpecialResolutionYesNoInput = (props) => {
  const classes = useStyles();
  const { ballotQuestion } = props;
  let choices = VoteChoices(props);
  return <RadioButtonGroupInput 
    row={true}
    optionText={<SpecialResolutionYesNoField />}
    source={"ballot_response[ballot_question_id=" + props.ballotQuestion.id + "]"} 
    choices={choices} 
    label=""
  />
}

const SpecialResolutionYesNoField = ({ record, votedForBallotResponseIds=[] }) => {
  const ballotResponse = record.ballotResponse;
  const ballotQuestion = record.ballotQuestion;
  const classes = useStyles();
  return (
      <Card className={`${classes.voteChoiceCard}  ${classes.VoteResponses} ${votedForBallotResponseIds.includes(ballotResponse.id) ? classes.votedForStyle : ""}`}>
        <CardContent className={classes.voteChoiceCardContent}>
          <Typography variant="h7" component="h5">
            {ballotResponse.title}
          </Typography>
          <RichTextField record={ballotResponse} source="content" />
        </CardContent>
      </Card>
  );
};


const CandidateElectionMultiSelectInput = (props) => {
  const classes = useStyles();
  const { ballotQuestion, ballotResponses } = props;
  let choices = VoteChoices(props);
  return (
    <div className={classes.gridRoot}>
      <Grid container spacing={1}>
        {ballotResponses && ballotResponses.map((response) => {
           let tmpRecord = {ballotQuestion: ballotQuestion, ballotResponse: response};
           return (
             <Grid item xs={12} spacing={2}>
               <CandidateElectionField record={tmpRecord} />
             </Grid>
           )
        })}
      </Grid>
      <RichTextField record={ballotQuestion} source="content" className={classes.richTextField} />
      <CheckboxGroupInput 
        row={false}
        optionText="ballotResponse.title"
        source={"ballot_response[ballot_question_id=" + props.ballotQuestion.id + "]"} 
        choices={choices} 
        label=""
      />
    </div>
  );
}

const CandidateElectionMultiSelectField = ({ record, votedForBallotResponseIds=[] }) => {
  const ballotResponse = record.ballotResponse;
  const ballotQuestion = record.ballotQuestion;
  const classes = useStyles();
  return (
      <Card className={`${classes.voteChoiceCard} ${votedForBallotResponseIds.includes(ballotResponse.id) ? classes.votedForStyle : ""}`}>
        <CardContent className={classes.voteChoiceCardContent}>
          <Typography variant="h7" component="h5">
            {ballotResponse.title}
          </Typography>
          <RichTextField record={ballotResponse} source="content" />
        </CardContent>
      </Card>
  );
};


const CandidateElectionSingleSelectInput = (props) => {
  const classes = useStyles();
  const { ballotQuestion, ballotResponses } = props;
  let choices = VoteChoices(props);
  return (
    <div className={classes.gridRoot}>
      <Grid container spacing={1}>
        {ballotResponses && ballotResponses.map((response) => {
           let tmpRecord = {ballotQuestion: ballotQuestion, ballotResponse: response};
           return (
             <Grid item xs={12} spacing={2}>
               <CandidateElectionField record={tmpRecord} />
             </Grid>
           )
        })}
      </Grid>
      <RichTextField record={ballotQuestion} source="content"  className={classes.richTextField} />
      <RadioButtonGroupInput 
        row={false}
        optionText="ballotResponse.title"
        source={"ballot_response[ballot_question_id=" + props.ballotQuestion.id + "]"} 
        choices={choices} 
        label=""
      />
      
    </div>
  )
};


const CandidateElectionField = ({ record, votedForBallotResponseIds=[]}) => {
  let ballotResponse = record.ballotResponse;
  const classes = useStyles();
  return (
      <ExpansionPanel defaultExpanded={true} className={`${votedForBallotResponseIds.includes(ballotResponse.id) ? classes.votedForStyle : ""}`}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.expansionTitle}>
            <Avatar src={ballotResponse.primary_image_url} className={classes.primaryImage} />
            <Typography variant="h5" className={classes.paddingLeft}>{ballotResponse.title}</Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <RichTextField record={ballotResponse} source="content" className={classes.richTextField} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
  );
};

const VoteChoices = (props) => {
  let ballotResponses = Object.values(props.ballotResponses);
  let choices = ballotResponses.map((br) => {
    return {id: br.id, name: props.ballotQuestion.id, ballotQuestion: props.ballotQuestion, ballotResponse: br}
  });
  return choices
}

const VoteableResponses = ({ballotQuestion, ballotResponses}) => {
  const classes = useStyles();
  let slug = ballotQuestion.ballot_question_type.slug;
  if (slug === 'candidate-election-single-select') {
    return <CandidateElectionSingleSelectInput 
                ballotQuestion={ballotQuestion} 
                ballotResponses={ballotResponses}
                className={classes.VoteResponses} />
  } else if (slug === 'special-resolution-yes-no') {
    return <SpecialResolutionYesNoInput 
                ballotQuestion={ballotQuestion} 
                ballotResponses={ballotResponses} />
  } else if (slug === 'candidate-election-multi-select') {
    return <CandidateElectionMultiSelectInput 
                ballotQuestion={ballotQuestion} 
                ballotResponses={ballotResponses}
                className={classes.VoteResponses} />
  }
  return (
    ballotResponses.map((br) => {
      return (
        <FallbackBallotResponse ballotResponse={br} />
      )
    })
  )
}

const FallbackBallotResponse = ({ballotResponse, votedForBallotResponseIds=[]}) => {
  const classes = useStyles();
  return (
    <div style={{ margin: '1em' }}>
      <Card key={ballotResponse.id} className={classes.nestedCardStyle}>
        <CardHeader
            title={<TextField record={ballotResponse} source="title" />}
        />
        <CardContent>
          <RichTextField record={ballotResponse} source="content" />
        </CardContent>
      </Card>
    </div>
  );
}

const BallotResponses = ({ballotQuestion, ballotResponses, votedForBallotResponseIds=[]}) => {
  let slug = ballotQuestion.ballot_question_type.slug;
  return (
    ballotResponses.map((br) => {
      let tmpRecord = {ballotQuestion: ballotQuestion, ballotResponse: br};
      if (slug === 'candidate-election-single-select' || slug === 'candidate-election-multi-select') {
        return <CandidateElectionField record={tmpRecord} votedForBallotResponseIds={votedForBallotResponseIds} />
      } else if (slug === 'special-resolution-yes-no') {
        return <SpecialResolutionYesNoField record={tmpRecord} votedForBallotResponseIds={votedForBallotResponseIds}/>
      } else {
        return <FallbackBallotResponse ballotResponse={br} votedForBallotResponseIds={votedForBallotResponseIds}/>
      }
    })
  );
}


export const BallotQuestionsGrid = ({ids, data, basePath, voteable=false, votedForBallotResponseIds=[]}) => {
    console.log("BallotQuestionsGrid here is the data", data)
    console.log("BallotQuestionsGrid here is the ids", ids)
    const classes = useStyles();
    return (
      <span>
        {!ids ? <CircularProgress /> : null}
        {ids && ids.map(id =>
          <div key={id} className={classes.cardStyle}>
            <CardHeader
                title={<TextField record={data[id]} source="title" className={classes.title}/>}
                avatar={<AvatarField id={id} data={data} basePath={basePath} className={classes.avatar}/>}
            />
            <div className={classes.richTextField}>
              <RichTextField record={data[id]} source="content" />
            </div>
            <div className={classes.voteChoiceCardContainer}>
              {
                voteable ? (
                  <VoteableResponses 
                    ballotResponses={data[id].ballot_responses} 
                    ballotQuestion={data[id]} 
                    votedForBallotResponseIds={votedForBallotResponseIds} 
                  /> )
                : <BallotResponses 
                    ballotResponses={data[id].ballot_responses} 
                    ballotQuestion={data[id]} 
                    votedForBallotResponseIds={votedForBallotResponseIds}
                  />
              }
            </div>
            <Divider />
          </div>
        )}
      </span>
    )
};

// export const BallotQuestionsGrid = ({ids, data, basePath, voteable=false, votedForBallotResponseIds=[]}) => {
//     console.log("BallotQuestionsGrid here is the data", data)
//     console.log("BallotQuestionsGrid here is the ids", ids)
//     const classes = useStyles();
//     return (
//       <span>
//         {!ids ? <CircularProgress /> : null}
//         {ids && ids.map(id =>
//           <Card key={id} className={classes.cardStyle}>
//             <CardHeader
//                 title={<TextField record={data[id]} source="title" className={classes.title}/>}
//                 avatar={<AvatarField id={id} data={data} basePath={basePath} className={classes.avatar}/>}
//             />
//             <CardContent>
//               <RichTextField record={data[id]} source="content" />
//             </CardContent>
//             <CardContent className={classes.voteChoiceCardContainer}>
//               {
//                 voteable ? (
//                   <VoteableResponses 
//                     ballotResponses={data[id].ballot_responses} 
//                     ballotQuestion={data[id]} 
//                     votedForBallotResponseIds={votedForBallotResponseIds} 
//                   /> )
//                 : <BallotResponses 
//                     ballotResponses={data[id].ballot_responses} 
//                     ballotQuestion={data[id]} 
//                     votedForBallotResponseIds={votedForBallotResponseIds}
//                   />
//               }
//             </CardContent>
//           </Card>
//         )}
//       </span>
//     )
// };


const BallotShow = (props) => {
  const classes = useStyles();

  const permissions = useSelector((state) => state.authData.permissions);
  const memberProfileID = useSelector((state) => state.authData.id);
  const votedForBallotIDs = useSelector((state) => state.authData.voted_on_ids.ballot_ids);
  const votedForBallotResponseIDs = useSelector((state) => state.authData.voted_on_ids.ballot_response_ids);
  let currentBallotID = props.id;

  return (
    <Show {...props} actions={null}>
      <SimpleShowLayout>
        <TextField label="" source="title" className={classes.titleClass} />
        <Divider fullWidth />
        <RichTextField source="content" label="" className={classes.bodyClass} />
        <span>*Your votes are highlighted in gold</span>
        <ReferenceManyField label="" reference="ballot_questions" target="ballot_id">
          <BallotQuestionsGrid votedForBallotResponseIds={votedForBallotResponseIDs} />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};


export default BallotShow