import React from 'react';
import PropTypes from 'prop-types';

const ReactMarkdown = require('react-markdown/with-html')

const MarkdownField = ({ source, record = {} }) => {
	return (
		<ReactMarkdown
		  source={record[source]}
		  escapeHtml={false}
		/>
	);
};

MarkdownField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default MarkdownField;