import React from "react";
import {
  Edit,
  TabbedEditLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  Button,
  UrlField,
  SimpleForm,
  FormTab,
  TextInput,
  ReferenceManyField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required, 
  useNotify, 
  useRefresh,
  useRedirect,
  SaveButton,
  NumberInput,
  Toolbar
} from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    titleClass: {
      fontSize: '20pt',
      fontWeight: '600',
      margin: '16px'
    },
    formWidth: {
      width: '50%',
      lineHeight: '28px'
    }
});

const CommentEdit = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const memberProfileID = useSelector((state) => state.authData.id);
  const orgID = useSelector((state) => state.authData.organization.id);
  const permissions = useSelector((state) => state.authData.permissions);
  // const ToolbarNoDelete = props => (
  //     <Toolbar {...props} >
  //         <SaveButton />
  //     </Toolbar>
  // );

  return (
    <Edit title="Edit Comment" successMessage="Saved successfully." {...props}>
      <SimpleForm redirect="list">
        <TextInput source="question" multiline className={classes.formWidth} />
        <TextInput source="answer" multiline className={classes.formWidth} />
        <BooleanInput source="public" label='Make viewable by all members' className={classes.formWidth} />
        <NumberInput source="priority" label='Priority (lowest number shows first)' />
      </SimpleForm>
    </Edit>
  );
};

export default CommentEdit;

