import { useStore, useEffect, useContext } from 'react';
import { useDataProvider } from 'react-admin';
import { ReactReduxContext, useDispatch } from 'react-redux'


export const apiUrl = () => {
	const env = process.env.NODE_ENV;
	if (env === 'production') {
		return "https://cuelection-api.herokuapp.com"
	} else if (env === 'staging') {
        return "https://cuelection-staging.herokuapp.com"
    }
	return "http://localhost:8000"
};

export const getCSRFToken = () => {
    let cookieValue = null;
    let name = 'csrftoken';
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            if (cookie) {
                cookie = cookie.trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }   
            }
            
        }
    } 
    return cookieValue;
}

export const removeCSRFToken = (token) => {
    localStorage.removeItem('csrftoken');
    document.cookie = "csrftoken=;";
	return token
};