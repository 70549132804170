import React from 'react';
import {
  fetchUtils,
  Admin,
  Resource,
  ListGuesser,
  ShowGuesser,
  EditGuesser,
  CreateGuesser,
  SimpleForm,
  TextInput,
  Create,
  } from 'react-admin';
import { Route } from 'react-router-dom';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import logo from './logo.svg';
import DataProvider from "./DataProvider";
import authProvider from './authProvider';

import AuthReducer from './AuthReducer';

import CustomLayout from "./CustomLayout";
import CustomLogin from './CustomLogin';

import LogList from "./logs/LogList";

import BallotList from "./ballots/BallotList";
import BallotShow from "./ballots/BallotShow";
import BallotEdit from "./ballots/BallotEdit";
import ExportVotesList from "./ballots/ExportVotesList";

import BallotQuestionList from "./ballot_questions/BallotQuestionList";
import BallotQuestionShow from "./ballot_questions/BallotQuestionShow";

import BallotResponseList from "./ballot_responses/BallotResponseList";
import BallotResponseShow from "./ballot_responses/BallotResponseShow";

import BallotQuestionTypeList from "./ballot_question_types/BallotQuestionTypeList";
import BallotQuestionTypeShow from "./ballot_question_types/BallotQuestionTypeShow";

import CommentList from "./comments/CommentList";
import CommentCreate from "./comments/CommentCreate";
import CommentEdit from "./comments/CommentEdit";

import MemberProfileShow from "./member_profiles/MemberProfileShow";
import MemberProfileEdit from "./member_profiles/MemberProfileEdit";

import RoleList from "./roles/RoleList";
import RoleShow from "./roles/RoleShow";

import AnnouncementList from "./announcements/AnnouncementList";
import AnnouncementShow from "./announcements/AnnouncementShow";

import AnnualMeetingList from "./annual_meetings/AnnualMeetingList";
import AnnualMeetingShow from "./annual_meetings/AnnualMeetingShow";

import BoardBookList from "./board_books/BoardBookList";
import BoardBookShow from "./board_books/BoardBookShow";

import OrganizationList from "./organizations/OrganizationList";
import OrganizationShow from "./organizations/OrganizationShow";

import VoteCreate from "./votes/VoteCreate";
import VoteList from "./votes/VoteList";

import { stylesReducer } from './styleReducer';
import { apiUrl, getCSRFToken } from './shared/api';

import AnnouncementIcon from '@material-ui/icons/Announcement';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import GavelIcon from '@material-ui/icons/Gavel';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CommentIcon from '@material-ui/icons/Comment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import './App.css';


export const myHttpClient = (url, options = {}) => {
  const token = getCSRFToken();
  // console.log('token', token)
  if (!options.headers) {
      options.headers = new Headers({
        Accept: 'application/json',
        // 'X-CSRFToken': token,
      });
  }
  options.headers.set('Authorization', `Token ${token}`);
  options.credentials = 'same-origin'

  // add a backslash to end of request...without this we can't send requests in safari
  var pos = url.indexOf('?');
  let _url = url;
  if (pos > -1) {
    var b = "/";
    _url = [url.slice(0, pos), b, url.slice(pos)].join('');
  } else if (url.charAt(url.length - 1) != "/") {
    _url += "/"
  }

  return fetchUtils.fetchJson(apiUrl() + _url, options);
}

const dataProvider = DataProvider('/api', myHttpClient);



englishMessages.ra.boolean.null = 'Both';
englishMessages.ra.boolean.false = 'No';
englishMessages.ra.boolean.true = 'Yes';
const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en');

//how to add another login page. use a custom route. prob can't have layout on it.
//https://codesandbox.io/s/github/marmelab/react-admin/tree/master/examples/simple
//this is the example site. can logout, then go to /#/custom adn see the route, then go to /#/custom2 and get redirected to login. https://l91qk8j2r7.sse.codesandbox.io/ 
//may not need but saving: https://stackoverflow.com/questions/54320260/how-to-implement-forgot-password-page-on-react-admin

class App extends React.Component {
  render() {
    return (
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={CustomLayout}
        customReducers={{ styles: stylesReducer, authData: AuthReducer }}
        loginPage={CustomLogin}
        i18nProvider={i18nProvider}
      >
          
          <Resource
            name="announcements"
            list={AnnouncementList}
            show={AnnouncementShow}
            icon={<AnnouncementIcon color="secondary"/>}
          />

          <Resource
            name="annual_meetings"
            list={AnnualMeetingList}
            show={AnnualMeetingShow}
            icon={<MeetingRoomIcon color="error" />}
            options={{"label": "Annual Meeting"}}
          />

          <Resource
            name="board_books"
            list={BoardBookList}
            show={BoardBookShow}
            icon={<AccountBalanceWalletIcon color="error" />}
            options={{"label": "Board Book"}}
          />

          <Resource
            name="elections"
            list={BallotList}
            show={BallotShow}
            icon={<HowToVoteIcon color="primary"/>}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="bylaw_amendments"
            list={BallotList}
            show={BallotShow}
            icon={<GavelIcon color="blue"/>}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="logs"
            list={LogList}
            icon={<ListAltIcon />}
            // show={BallotShow}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="ballot_votes"
            // list={MembershipList}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="ballot_questions"
            // list={BallotQuestionList}
            // show={BallotQuestionShow}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="ballot_responses"
            // list={BallotResponseList}
            // show={BallotResponseShow}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="ballot_question_types"
            // list={BallotQuestionTypeList}
            // show={BallotQuestionTypeShow}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="member_profiles"
            // list={MemberList}
            show={MemberProfileShow}
            edit={MemberProfileEdit}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="roles"
            // list={RoleList}
            // show={RoleShow}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="organizations"
            // list={OrganizationList}
            show={OrganizationShow}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
          />

          <Resource
            name="votes"
            // list={VoteList}
            create={VoteCreate}
            // icon={<DoneOutlineIcon color="secondary"/>}
          />

          <Resource
            name="comments"
            list={CommentList}
            edit={CommentEdit}
            create={CommentCreate}
            icon={<CommentIcon color="secondary"/>}
          />

          <Resource
            name="ballots"
            list={ExportVotesList}
            options={{"label": "Export Votes"}}
            // show={BallotShow}
            // edit={EditGuesser}
            // edit={BallotEdit}
            // create={CreateGuesser}
            icon={<DoneOutlineIcon color="secondary"/>}
          />


      </Admin>
    );
  }
}

export default App;
