import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  Button,
  ReferenceField,
  ChipField,
  UrlField,
  SimpleShowLayout,
  useDataProvider,
  ReferenceManyField,
  SingleFieldList,
  Datagrid
} from 'react-admin';
import VoteCreate from '../votes/VoteCreate';
import { useStore } from 'react-redux';
import MarkdownField from '../shared/MarkdownField';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import CommentCreate from '../comments/CommentCreate';
import Typography from '@material-ui/core/Typography';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, DefaultLayoutPluginProps } from '@react-pdf-viewer/default-layout';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

const useStyles = makeStyles({
    titleClass: {
      fontSize: '30pt',
      textAlign: 'center',
      fontWeight: '600',
    },
    pdfViewer: {
      border: '1px solid rgba(0, 0, 0, 0.3)',
      // height: '750px',
      marginTop: '1rem',
      height: '750px',
      width: '100vw',
      ['@media (min-width:1000px)']: {
        width: '75vw'
      }
    }
});


const BoardBookBallot  = (props) => {
  return (
    <div>
      {
        props && props.data && Object.keys(props.data).map((id) => {
          return <VoteCreate {...props} basePath={'/votes'} resource={'votes'} ballotID={id} redirectPath={props.boardBookID} />
        })
      }
    </div>
  )
}

export const BoardBookPDFViewer = (props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    props: DefaultLayoutPluginProps
  });

  //zoom to PageFit on PDF if page_fit boolean is set to true 
  const zoomPluginInstance = zoomPlugin();
  const { zoomTo } = zoomPluginInstance;
  if (props && props.record && props.record.page_fit) {
    zoomTo(SpecialZoomLevel.PageFit);
  }

  const classes = useStyles();
  let boardBookURL = props && props.record && props.record.url;
  if (boardBookURL) {
    return (
      <div className={classes.pdfViewer}>
        <Viewer
            fileUrl={boardBookURL}
            plugins={[
                defaultLayoutPluginInstance,
                zoomPluginInstance
            ]}
        />
      </div>
    )
  }
  return null
}

const BoardBookComment = (props) => {
  let boardBook = props && props.record;
  if (boardBook && boardBook.allow_comments) {
    return (
      <div>
        <Divider />
        <Typography variant='h6'>Have a comment for the admin?</Typography>
        <CommentCreate {...props} boardBookID={props.boardBookID} basePath={'/comments'} resource={'comments'} />
      </div>
    )
  }
  return null;
}


const BoardBookShow = (props) => {
  //create log 
  const dataProvider = useDataProvider();
  const store = useStore();
  const state = store.getState();

  let memberProfileID = state && state.authData && state.authData.id;
  let orgID = state && state.authData && state.authData.organization && state.authData.organization.id;
  let boardBookID = props && props.id;
  
  if (memberProfileID && orgID && boardBookID) {
    dataProvider.create('logs', { data: {member_profile: memberProfileID, organization: orgID, board_book: boardBookID} })
  }

  const classes = useStyles();
  
  return (
    <Worker
            workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
        >
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="title" label="" className={classes.titleClass} />
          <Divider />
          <MarkdownField source="content" />
          <BoardBookPDFViewer />
          <ReferenceManyField reference="ballots" target='board_book_id' label="">
            <BoardBookBallot boardBookID={boardBookID} />
          </ReferenceManyField>
          <BoardBookComment boardBookID={boardBookID} />
        </SimpleShowLayout>
      </Show>
    </Worker>
  );
};


export default BoardBookShow