// in ./ResetViewsButton.js
import * as React from "react";
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import { VisibilityOff } from '@material-ui/icons';


const ResetViewsButton = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const resetViews = () => {
        unselectAll('logs')
    }

    return (
        <Button
            label="Cancel"
            onClick={resetViews}
        >
            <VisibilityOff />
        </Button>
    );
};

export default ResetViewsButton;