import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  Button,
  ReferenceField,
  ChipField,
  UrlField,
  SimpleShowLayout,
  useDataProvider,
  ReferenceManyField,
  SingleFieldList,
  Datagrid
} from 'react-admin';
import { Worker } from '@react-pdf-viewer/core';
import VoteCreate from '../votes/VoteCreate';
import { BoardBookPDFViewer } from '../board_books/BoardBookShow';
import { useStore } from 'react-redux';
import MarkdownField from '../shared/MarkdownField';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import CommentCreate from '../comments/CommentCreate';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    titleClass: {
      fontSize: '30pt',
      textAlign: 'center',
      fontWeight: '600',
    },
});



const AnnualMeetingBallot  = (props) => {
  return (
    <div>
      {
        props && props.data && Object.keys(props.data).map((id) => {
          return <VoteCreate {...props} basePath={'/votes'} resource={'votes'} ballotID={id} redirectPath={props.annualMeetingID} />
        })
      }
    </div>
  )
}


const AnnualMeetingComment = (props) => {
  let annualMeeting = props && props.record;
  if (annualMeeting && annualMeeting.allow_comments) {
    return (
      <div>
        <Divider />
        <Typography variant='h6'>Have a comment for the admin?</Typography>
        <CommentCreate {...props} annualMeetingID={props.annualMeetingID} basePath={'/comments'} resource={'comments'} />
      </div>
    )
  }
  return null;
}


const AnnualMeetingShow = (props) => {
  //create log 
  const dataProvider = useDataProvider();
  const store = useStore();
  const state = store.getState();

  let memberProfileID = state && state.authData && state.authData.id;
  let orgID = state && state.authData && state.authData.organization && state.authData.organization.id;
  let annualMeetingID = props && props.id;
  
  if (memberProfileID && orgID && annualMeetingID) {
    dataProvider.create('logs', { data: {member_profile: memberProfileID, organization: orgID, annual_meeting: annualMeetingID} })
  }

  const classes = useStyles();
  
  return (
    <Worker
            workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"
        >
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="title" label="" className={classes.titleClass} />
          <Divider />
          <MarkdownField source="content" />
          <BoardBookPDFViewer />
          <Divider />
          <ReferenceManyField reference="ballots" target='annual_meeting_id' label="">
            <AnnualMeetingBallot annualMeetingID={annualMeetingID} />
          </ReferenceManyField>
          <AnnualMeetingComment annualMeetingID={annualMeetingID} />
        </SimpleShowLayout>
      </Show>
    </Worker>
  );
};


export default AnnualMeetingShow