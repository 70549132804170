import React from "react";
import {
  List,
  TextField,
  Datagrid,
  RichTextField,
  ShowButton,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  FunctionField,
  Filter,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  TextInput,
  Pagination,
  Button,
  useRedirect

} from 'react-admin';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    announcementRow: {
      fontSize: '15pt',
    },
}));

const AnnouncementPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} {...props} />;

const AnnouncementList = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const permissions = useSelector((state) => state.authData.permissions);
  const orgID = useSelector((state) => state.authData && state.authData.organization && state.authData.organization.id);

  if (permissions && !permissions.includes("announcements")) {
    if (permissions.includes("annual_meetings")) {
      redirect('/annual_meetings')
    } else {
      redirect('/' + permissions[0])
    }
  }
  return (
    <List
      exporter={false}
      bulkActionButtons={false}
      // filters={<AnnouncementFilter/>}
      filter={{ organization_id: orgID }}
      pagination={<AnnouncementPagination />}
      {...props}
    >
      <Datagrid
        isRowSelectable={(record) => false}
        rowClick={"show"}
      >
        <TextField source="title" className={classes.announcementRow}/>
      </Datagrid>

    </List>
  )
}

export default AnnouncementList