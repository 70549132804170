import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  Button,
  UrlField,
  ReferenceManyField,
  ChipField,
  SingleFieldList,
  SimpleShowLayout
} from 'react-admin';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import {ImgLogo} from './CustomAppBar';


const useStyles = makeStyles(theme => ({
  footer: {
  	backgroundColor: theme.palette.primary.light,
  	position: 'relative',
  	right: 0,
  	bottom: 0,
  	left: 0,
  	zIndex: 100,
 	  padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  bigText: {
  	color: theme.palette.primary.contrastText,
  	fontSize: '15pt',
  }
}));


const Footer = (props) => {
	const classes = useStyles();
  let authData = useSelector((state) => state?.authData);
  let organization = authData && authData.organization;
  let orgName = organization && organization.name;
  let imgUrl = organization && organization.secondary_image_url;
  let supportPhone = organization && organization.support_phone;
  let homepageUrl = organization && organization.homepage_url;
	return (
		<div className={classes.footer}>
      <ImgLogo src={imgUrl} overrideClass={"bigLogo"}/>
			<p className={classes.bigText}>Call us: {supportPhone}</p>
			<a href={homepageUrl} target="_blank" className={classes.bigText}>{orgName} Homepage</a>
		</div>
	);
};


export default Footer
