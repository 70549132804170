import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  Button,
  UrlField,
  ReferenceManyField,
  ChipField,
  SingleFieldList,
  SimpleShowLayout
} from 'react-admin';

const MemberShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="email" />
        <TextField source="member_identifier" />
        />
      </SimpleShowLayout>
    </Show>
  );
};


export default MemberShow