import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  Button,
  ReferenceField,
  ChipField,
  UrlField,
  SimpleShowLayout
} from 'react-admin';


import MarkdownField from '../shared/MarkdownField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    titleClass: {
      fontSize: '30pt',
      textAlign: 'center',
      fontWeight: '600',
    },
});


const AnnouncementShow = (props) => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="title" label="" className={classes.titleClass} />
        <MarkdownField source="content" />
      </SimpleShowLayout>
    </Show>
  );
};


export default AnnouncementShow