import React, {
    HtmlHTMLAttributes,
    ReactNode,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    Card,
    Avatar,
    createMuiTheme,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';

import Notification from './Notification';
import CustomLoginForm from './CustomLoginForm';

interface Props {
    backgroundImage?: string;
    children: ReactNode;
    classes?: object;
    className?: string;
    staticContext?: StaticContext;
    theme: object;
}

const defaultTheme = {
    palette: {
        secondary: {
            light: '#6ec6ff',
            main: '#2196f3',
            dark: '#0069c0',
            contrastText: '#fff',
        },
    },
    typography: {
        title: {
            fontWeight: 400,
        },
    },
    sidebar: {
        width: 240,
        closedWidth: 55,
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage:
                'linear-gradient(#CD1F43, #4a0413)' //alabama cu
                // 'linear-gradient(#0E2A00, #FFF725)' //fort mcclellan
                // 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
                // 'radial-gradient(circle at 50% 14em, #82162c 50%, #CD1F43 80%, #CD1F43 100%)',
        },
        card: {
            minWidth: 300,
            // marginTop: '6em',
            marginTop: '2em'
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: theme.palette.secondary[500],
        },
        typography: {
            textAlign: 'center',
            fontWeight: 400,
        },
        loginImage: {
            marginTop: '1rem',
            width: '400px',
        }
    }),
    { name: 'RaLogin' }
);

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
const Login: React.FunctionComponent<
    Props & HtmlHTMLAttributes<HTMLDivElement>
> = props => {
    const {
        theme,
        classes: classesOverride,
        className,
        children,
        staticContext,
        backgroundImage,
        ...rest
    } = props;
    const containerRef = useRef();
    const classes = useStyles(props);
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });

//                     <img src="https://cu-dev-uploads.s3.us-east-2.amazonaws.com/staging/
// FMCUWebsiteLogo_a37d0d50-38ee-4ea3-812a-b19d62c0ae8a.png" className={classes.loginImage} />

// <img src="https://cu-dev-uploads.s3.us-east-2.amazonaws.com/staging/aculogo.png" className={classes.loginImage} />

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                {...rest}
                ref={containerRef}
            >

                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    {children}
                </Card>
                <Notification />
            </div>
        </ThemeProvider>
    );
};

Login.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

Login.defaultProps = {
    theme: defaultTheme,
    children: <CustomLoginForm />,
};

export default Login;