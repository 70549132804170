import React from "react";
import {
  Edit,
  TabbedEditLayout,
  Tab,
  TextField,
  RichTextField,
  FunctionField,
  Button,
  UrlField,
  SimpleForm,
  FormTab,
  TextInput,
  ReferenceManyField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required, 
  useNotify, 
  useRefresh,
  useRedirect,
  SaveButton,
  Toolbar
} from 'react-admin';
import { useSelector } from 'react-redux';

import RichTextInput from 'ra-input-rich-text';

const MemberProfileEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const memberProfileID = useSelector((state) => state.authData.id);

  const onFailure = (error) => {
      let errors = Object.keys(error.body);
      let message = errors.join(", ")
      notify(`Error saving: ${message}`)
      refresh();
  };

  const onSuccess = ({data}) => {
      notify("Successfully saved your profile!")
      refresh();
  };

  const ToolbarNoDelete = props => (
      <Toolbar {...props} >
          <SaveButton color="secondary" />
      </Toolbar>
  );

  return (
    <Edit onFailure={onFailure} undoable={false} title="Edit Profile" onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<ToolbarNoDelete />} redirect="edit">
        <TextInput source="first_name" />
        <TextInput source="last_name" disabled />
        <TextInput source="email" />
        <TextInput source="member_identifier" disabled />
      </SimpleForm>
    </Edit>
  );
};

export default MemberProfileEdit;

