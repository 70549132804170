import React, { ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
// @ts-ignore
import inflection from 'inflection';
import { makeStyles, useMediaQuery, Theme } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import classnames from 'classnames';
import { getResources, useTranslate, Translate, ReduxState } from 'ra-core';

import { DashboardMenuItem, MenuItemLink } from 'react-admin';

const useStyles = makeStyles(theme => {
    return ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        },
        active: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main 
            }
        },
    })
});

function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const translatedResourceName = (resource, translate) =>
    translate(`resources.${resource.name}.name`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? toTitleCase(translate(resource.options.label, {
                      smart_count: 2,
                      _: resource.options.label,
                  }))
                : toTitleCase(inflection.humanize(inflection.pluralize(resource.name))),
    });

const Menu = props => {
    const {
        classes: classesOverride,
        className,
        dense,
        hasDashboard,
        onMenuClick,
        logout,
        ...rest
    } = props;

    const translate = useTranslate();
    const classes = useStyles(props);
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources, shallowEqual);
    const permissions = useSelector((state) => state.authData.permissions);

    // Used to force redraw on navigation
    useSelector((state) => state.router.location.pathname);

    return (
        <div className={classnames(classes.main, className)} {...rest}>

            {resources && resources.map(resource => {
                if(permissions && permissions.includes(resource.name)){
                    return(
                        <MenuItemLink
                            classes={classes}
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={translatedResourceName(
                                resource,
                                translate
                            )}
                            leftIcon={
                                resource.icon ? React.cloneElement(resource.icon, {color: "secondary"}) : <DefaultIcon />
                            }
                            onClick={onMenuClick}
                            dense={dense}
                            sidebarIsOpen={open}
                        />
                    )
                }
            })}
            {isXSmall && logout}
        </div>
    );
};




Menu.defaultProps = {
    onMenuClick: () => null,
};

export default Menu;