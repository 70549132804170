import React, { FunctionComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { useRedirect } from 'react-admin';
import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { useDispatch } from 'react-redux';
import axios from 'axios';

interface Props {
    redirectTo?: string;
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        hidden: {
            display: 'none',
        },
        button: {
            width: '100%',
        },
        instructions: {
            textAlign: 'center',
            padding: '1em',
            paddingTop: '0em'
        },
        miniText: {
            textAlign: 'center',
            padding: '1em',
            paddingTop: '0em',
            fontSize: '12px'
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);


const getReadableName = name => {
    name = name.replace('_', ' ');
    const words = name.split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
}



const CustomLoginForm = props => {
    const { redirectTo } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const [postLoginFields, setPostLoginFields] = useSafeSetState(null);
    const [memberProfile, setMemberProfile] = useSafeSetState(null);
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);
    const redirect = useRedirect();
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = { last_name: undefined, member_identifier: undefined };
        if (Object.keys(values).length === 0){
            return {}
        }

        if (!values.member_identifier) {
            errors.member_identifier = translate('ra.validation.required');
        }
        if (!values.last_name) {
            errors.last_name = translate('ra.validation.required');
        }
        return errors;
    }

    const memberNeedsToUpdateFields = (member, fields) => {
        let shouldUpdate = false;
        for (let i=0; i<fields.length; i++) {
            let key = fields[i];
            let val = member[key];
            if (!val) {
                shouldUpdate = true;
            }
        }
        return shouldUpdate
    }

    const submit = (values) => {
        //how to add another login page. use a custom route. prob can't have layout on it.
        //https://codesandbox.io/s/github/marmelab/react-admin/tree/master/examples/simple
        //this is the example site. can logout, then go to /#/custom adn see the route, then go to /#/custom2 and get redirected to login. https://l91qk8j2r7.sse.codesandbox.io/
        //may not need but saving: https://stackoverflow.com/questions/54320260/how-to-implement-forgot-password-page-on-react-admin
        setLoading(true);

        // Prefill login credentials
        const prefilled = window.location.hash.includes('?')
        let lastName, memberIdentifier;
        if (prefilled) {
            let queryparams = window.location.hash.split('?')[1];
            let params = queryparams.split('&');
            params.forEach(function(lastNameAndMemberIdentifier) {
                if (lastNameAndMemberIdentifier.includes('lastName')) {
                    lastName = lastNameAndMemberIdentifier.split('=')[1];
                } else {
                    memberIdentifier = lastNameAndMemberIdentifier.split('=')[1];
                }
            });
        }

        let body = {
            last_name: lastName ? lastName : values.last_name,
            member_identifier: memberIdentifier ? memberIdentifier : values.member_identifier
        }
        console.log('xx.body', body)
        axios.post('/api/login/', body)
            .then(function (response) {
                setLoading(false);
                let error = response && response.data && response.data.error;
                if (error) {
                    notify(
                        error,
                        'warning'
                    );
                } else {
                    // login was successful
                    let memberPro = response && response.data;
                    let organization = memberPro && memberPro.organization;
                    let postLoginFields = organization && organization.post_login_fields;

                    setMemberProfile(memberPro)
                    dispatch({"type": "AUTH_RECEIVED", "payload": {"data": response.data}})
                    // THE POST-SIGNUP-STUFF
                    if (postLoginFields && postLoginFields.length > 0 && (memberNeedsToUpdateFields(memberPro, postLoginFields) || memberPro.multi_user)) {
                        setPostLoginFields(postLoginFields)
                    } else {
                        redirect('/')
                    }
                }
            })
            .catch(function (error) {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message === 'Bad Request' ? "Couldn't verify login information." : error.message,
                    'warning'
                );
            })
    }

    const validatePostLogin = (postLoginValues) => {
        // let keys = Object.keys(postLoginValues);
        // let errors = {};
        // for (let i=0; i<keys.length; i++) {
        //     errors[keys[i]] = undefined
        // }

        // for (let j=0; j<keys.length; j++) {
        //     let key = keys[j];
        //     if (!postLoginValues[key]) {
        //         errors[key] = translate('ra.validation.required');
        //     }
        // }
        // return errors;
    }

    const submitPostLogin = (postLoginValues) => {
        setLoading(true);
        let memberProfileID = memberProfile && memberProfile.id;
        // add ID & remove member_identifier (that gets overwritten)
        postLoginValues["id"] = memberProfileID;
        delete postLoginValues['member_identifier'];
        axios.post('/api/post_login/', postLoginValues)
            .then(function (response) {
                let error = response && response.data && response.data.error;
                if (error) {
                    setLoading(false);
                    notify(
                        error,
                        'warning'
                    );
                } else {
                    // login was successful
                    dispatch({"type": "AUTH_RECEIVED", "payload": {"data": response.data}})
                    let memberProfile = response && response.data;
                    setMemberProfile(memberProfile);
                    setLoading(false);
                    redirect('/');
                }
            })
            .catch(function (error) {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message === 'Bad Request' ? "Couldn't save your information." : error.message,
                    'warning'
                );
            })
    }

    return postLoginFields ?
           <Form
            onSubmit={submitPostLogin}
            validate={validatePostLogin}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Typography className={classes.instructions}>Enter your info below.</Typography>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            {postLoginFields && postLoginFields.map(fieldName => {
                                    return (
                                        <Field
                                            id={"post_login_" + fieldName}
                                            name={fieldName}
                                            defaultValue=''
                                            component={Input}
                                            label={getReadableName(fieldName)}
                                            disabled={loading}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                    <Typography className={classes.miniText}>Members who join virtually by Zoom are not eligible for door prizes.</Typography>
                </form>
            )}
        />
    :
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                // <form onSubmit={handleSubmit} noValidate>
                //     <Typography className={classes.instructions}>Sign in below</Typography>
                //     <div className={classes.form}>
                //         <div className={classes.input}>
                //             <Field
                //                 autoFocus
                //                 id="last_name"
                //                 name="last_name"
                //                 defaultValue=""
                //                 component={Input}
                //                 label="Last Name"
                //                 disabled={loading}
                //             />
                //         </div>
                //         <div className={classes.input}>
                //             <Field
                //                 id="member_identifier"
                //                 name="member_identifier"
                //                 type="password"
                //                 defaultValue=""
                //                 component={Input}
                //                 label="Member ID"
                //                 disabled={loading}
                //             />
                //         </div>
                //     </div>
                //     <CardActions>
                //         <Button
                //             variant="contained"
                //             type="submit"
                //             color="primary"
                //             disabled={loading}
                //             className={classes.button}
                //         >
                //             {loading && (
                //                 <CircularProgress
                //                     className={classes.icon}
                //                     size={18}
                //                     thickness={2}
                //                 />
                //             )}
                //             {translate('ra.auth.sign_in')}
                //         </Button>
                //     </CardActions>
                // </form>

                <form onSubmit={handleSubmit} noValidate>
                    <Typography className={classes.instructions}>Here for the Alabama Credit Union Annual Meeting?</Typography>
                    <div className={classes.form}>
                        <div className={classes.hidden}>
                            <Field
                                autoFocus
                                id="last_name"
                                name="last_name"
                                defaultValue="alabamacu"
                                component={Input}
                                label="Last Name"
                                disabled={loading}
                            />
                        </div>
                        <div className={classes.hidden}>
                            <Field
                                id="member_identifier"
                                name="member_identifier"
                                type="password"
                                defaultValue="2023"
                                component={Input}
                                label="Member ID"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                </form>
            )}
        />

};

CustomLoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default CustomLoginForm;
