import React from "react";
import {
  List,
  TextField,
  Datagrid,
  RichTextField,
  ShowButton,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  FunctionField,
  Filter,
  SelectInput,
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  Pagination,
  TextInput,
  ReferenceManyField,
  DateField,
  Button,
  useRedirect,
} from 'react-admin';
import { linkToRecord } from 'ra-core'

import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    annualMeetingRow: {
      fontSize: '15pt',
    },
    rightButton: {
      marginLeft: '20px',
    },
    goButton: {
      textAlign: 'center',
      paddingRight: '0.5em'
    },
}));

const AnnualMeetingPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} {...props} />;

const AdminShowButtons = props => {
  const classes = useStyles();
  let pathname = "/annual_meetings/"+props.record.id+"/show";
  if (props?.isAdmin && props?.record?.ballots?.length > 0) {
    let adminPathname = "/admin_annual_meetings/"+props.record.id+"/show";
    return <React.Fragment>
      <Button
          label="Show"
          component={Link}
          to={{ pathname: pathname }}
          color="primary"
          className={classes.goButton}
      />
      <Button
          label="See Votes"
          component={Link}
          to={{ pathname: adminPathname }}
          color="secondary"
          className={`${classes.rightButton} ${classes.goButton}`}
      />
    </React.Fragment>
  }
  return <Button
          label="Show"
          component={Link}
          to={{ pathname: pathname }}
          color="primary"
          className={classes.goButton}
      />
}

const handleRowClick = (id, basePath, record) => {
  const recordPath = linkToRecord(basePath, id)
  if (record && record.open_in_new_tab) {
    window.open(`#${recordPath}/show`)
  } else {
    window.location = `#${recordPath}/show`
  }
}

const AnnualMeetingList = (props) => {
  const classes = useStyles();
  const orgID = useSelector((state) => state.authData.organization.id);
  const permissions = useSelector((state) => state.authData.permissions);
  const isAdmin = permissions && permissions.includes('admin');

  return (
    <List
      exporter={false}
      // filters={<AnnualMeetingFilter/>}
      sort={{ field: "priority", order: "ASC" }}
      filter={{ organization_id: orgID }}
      bulkActionButtons={false}
      pagination={<AnnualMeetingPagination />}
      {...props}
    >
      <Datagrid
        isRowSelectable={(record) => false}
        // rowClick={isAdmin ? null : "show"}
        /*rowClick={"show"}*/
        rowClick={(id, basePath, record) => {handleRowClick(id, basePath, record)}}
      >
        <TextField source="title" className={classes.annualMeetingRow} />
      </Datagrid>

    </List>
  )
}

        // <AdminShowButtons isAdmin={isAdmin} />
export default AnnualMeetingList