import React, { useState } from 'react';
import { Loading } from 'react-admin';
import { useLogout } from 'react-admin';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    loadingContainer: {
    	display: 'flex',
    	flexDirection: 'column',
    	alignItems: 'center',
    	height: '100vh',
    },
    loadingIcon: {
    	maxHeight: '50%',
    }
}));


const LoadingWithLogout = () => {
	const classes = useStyles();
	const logout = useLogout();
	const handleClick = () => logout();
	const [showLogout, setShowLogout] = useState(false);
	setTimeout(function() {
		setShowLogout(true)
	}, 7000)
	return (
		<div className={classes.loadingContainer}>
			<Loading className={classes.loadingIcon}/> 
			{showLogout ? <ShowLogoutButton clickHandler={handleClick} /> : null}
		</div>
	);
};

const ShowLogoutButton = (props) => {
	let {clickHandler} = props;
	return (
		<React.Fragment>
			<Typography>
				This is taking longer than usual. Something might be wrong. Try logging out and logging back in.
			</Typography>
			<br />
			<Button onClick={clickHandler} color="primary" variant="contained">
				Logout
			</Button>
		</React.Fragment>
	)
}

export default LoadingWithLogout;