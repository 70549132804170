import React, {
    Component,
    createElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
    createMuiTheme,
    withStyles,
    createStyles,
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import compose from 'recompose/compose';
import CustomTheme from "./CustomTheme";

import { AppBar as DefaultAppBar } from 'react-admin';
import { Sidebar as DefaultSidebar } from 'react-admin';
import { Error as DefaultError } from 'react-admin';
import { Menu as DefaultMenu } from 'react-admin';
import { Notification as DefaultNotification } from 'react-admin';
import LoadingWithLogout from './shared/LoadingWithLogout';

import { ComponentPropType } from 'ra-core';
import { stylesReceived } from './styleReducer';
import authDataReceived from './AuthReducer';
import DataProvider from './DataProvider';
import {myHttpClient} from './App';
import {getCSRFToken} from './shared/api';

import CustomMenu from './CustomMenu';
import CustomAppBar from './CustomAppBar';
import Footer from './Footer';
import { useStore, getState } from 'react-redux';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { apiUrl } from './shared/api';

const styles = theme =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            minHeight: '100vh',
            backgroundColor: theme.palette.background.default,
            position: 'relative',
            minWidth: 'fit-content',
            width: '100%',
        },
        appFrame: {
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            [theme.breakpoints.up('xs')]: {
                marginTop: theme.spacing(6),
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(7),
            },
        },
        contentWithSidebar: {
            display: 'flex',
            flexGrow: 1,
            marginTop: '20px',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            flexBasis: 0,
            padding: theme.spacing(3),
            paddingTop: theme.spacing(1),
            paddingLeft: 0,
            [theme.breakpoints.up('xs')]: {
                paddingLeft: 5,
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
    });

const sanitizeRestProps = ({
    staticContext,
    history,
    location,
    match,
    ...props
}) => props;

class Layout extends Component {
    state = { 
        hasError: false, 
        errorMessage: null, 
        errorInfo: null,
    };

    constructor(props) {
        super(props);
        /**
         * Reset the error state upon navigation
         *
         * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
         */
        props.history.listen(() => {
            if (this.state.hasError) {
                this.setState({ hasError: false });
            }
        });
    }

    componentDidCatch(errorMessage, errorInfo) {
        this.setState({ 
            hasError: true, 
            errorMessage, 
            errorInfo 
        });
    }

    render() {
        const {
            children,
            classes,
            className,
            customRoutes,
            error,
            dashboard,
            logout,
            notification,
            open,
            sidebar,
            title,
            ...props
        } = this.props;

        const { hasError, errorMessage, errorInfo } = this.state;
        return (
            <div
                className={classnames('layout', classes.root, className)}
                {...sanitizeRestProps(props)}
            >
                <div className={classes.appFrame}>
                    {createElement(CustomAppBar, { title, open, logout })}
                    <main className={classes.contentWithSidebar}>
                        {createElement(sidebar, {
                            children: createElement(CustomMenu, {
                                logout,
                                hasDashboard: !!dashboard,
                            }),
                        })}
                        <div className={classes.content}>
                            {hasError
                                ? createElement(error, {
                                      error: errorMessage,
                                      errorInfo,
                                      title,
                                  })
                                : children}
                        </div>
                    </main>
                    <Footer />
                    {createElement(notification)}
                </div>
            </div>
        );
    }
}




Layout.propTypes = {
    appBar: ComponentPropType,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    classes: PropTypes.object,
    className: PropTypes.string,
    customRoutes: PropTypes.array,
    dashboard: ComponentPropType,
    error: ComponentPropType,
    history: PropTypes.object.isRequired,
    logout: PropTypes.element,
    // menu: ComponentPropType,
    notification: ComponentPropType,
    open: PropTypes.bool,
    sidebar: ComponentPropType,
    title: PropTypes.node.isRequired,
};

Layout.defaultProps = {
    appBar: DefaultAppBar,
    error: DefaultError,
    // menu: DefaultMenu,
    notification: DefaultNotification,
    sidebar: DefaultSidebar,
};

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
    connect(
        mapStateToProps,
        {} // Avoid connect passing dispatch in props
    ),
    withRouter,
    withStyles(styles, { name: 'RaLayout' })
)(Layout);


const LayoutWithTheme = props => {  
    const dispatch = useDispatch();
    const dataProvider = DataProvider('/api', myHttpClient);

    let memberProfileID = useSelector((state) => state?.authData?.id);
    let authData = useSelector((state) => state?.authData);
    let org = useSelector((state) => state?.authData?.organization);

    let token = getCSRFToken();
    
    if (token && !org) {
        dataProvider.getOne('member_profiles', {id: memberProfileID ? memberProfileID : null})
            .then((resp) => {
                let data = resp.data;
                if (data) {
                    dispatch({"type": "AUTH_RECEIVED", "payload": {"data": data}})
                    
                    //create log 
                    org = data && data.organization;
                    memberProfileID = data && data.id;
                    if (org && memberProfileID) {
                        dataProvider.create('logs', { data: {member_profile: memberProfileID, organization: org.id} });
                    }
                }                
            })

        //create log
        if (org && memberProfileID) {
            dataProvider.create('logs', { data: {member_profile: memberProfileID, organization: org.id} });
        }
    }

    const theme = org && org.style ? CustomTheme(org.style) : CustomTheme(null);
    const isLoggedIn = getCSRFToken();
    if (isLoggedIn && !org){
        return <LoadingWithLogout />
    } else {
        return (
            <ThemeProvider theme={theme}>
                <EnhancedLayout {...props} />
            </ThemeProvider>
        );
    }
}


const mapStateEnhancedLayoutToProps = (state, ownProps) => ({
    styles: state.styles
});

let ConnectedLayoutWithTheme = connect(mapStateEnhancedLayoutToProps, {stylesReceived, authDataReceived})(LayoutWithTheme)

export default ConnectedLayoutWithTheme;
