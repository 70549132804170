import * as React from "react";
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import MemberProfileEdit from "./member_profiles/MemberProfileEdit";
import { useSelector } from 'react-redux';

const EditMemberProfileLink = React.forwardRef((props, ref) => (
    <MenuItemLink
        ref={ref}
        to={'/member_profiles/' + props.memberProfileID + '/edit'}
        primaryText="Edit Profile"
        leftIcon={<SettingsIcon />}
        onClick={props.onClick} // close the menu on click
    />
));

const CustomUserMenu = props => {
    const memberProfileID = useSelector((state) => state.authData.id);

    return <UserMenu {...props}>
        <EditMemberProfileLink memberProfileID={memberProfileID} />
    </UserMenu>
};

export default CustomUserMenu;