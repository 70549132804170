// in src/CustomAppBar.js
import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import CustomUserMenu from './CustomUserMenu';
import { useSelector } from 'react-redux';

const useStyles = makeStyles( theme => {
    return ({
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        spacer: {
            flex: 1,
        },
        logo: {
            paddingRight: '10px',
            height: '55px',
            width: 'auto'
        },
        bigLogo: {
            height: '80px',
            width: 'auto'
        },
        appbar: {
            height: '75px',
            display: 'flex',
            justifyContent: 'center',
            'button': {
                fontSize: 20,
            },
            backgroundColor: theme.palette.secondary.dark
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
        },
        root: {
            fontSize: 20,
        },
        textLogo: {
            lineHeight: 1.2,
        },
        textAecu: {
            lineHeight: 1.2,
            fontWeight: 650,
            fontFamily: theme.typography.fontFamily,
            padding: 4,
            borderRight: '1px solid white',
            margin: 8
        },
        lightColor: {
            color: theme.palette.primary.light
        },
        darkColor: {
            color: theme.palette.secondary.dark
        }
    })
});



export const TextLogo = props => {
    const classes = useStyles();
    const aecuClasses = props && props.dark ? `${classes.textAecu} ${classes.darkColor}` : `${classes.textAecu} ${classes.lightColor}`
    const textLogoClasses = props && props.dark ? `${classes.textLogo} ${classes.darkColor}` : `${classes.textLogo} ${classes.lightColor}`
    return (

        <div className={classes.container}>
             <Typography 
             variant="subtitle1" 
             className={aecuClasses} 
             gutterBottom={false}
            >
                 AE<br/>CU 
             </Typography>
             <Typography  
                 variant="caption" 
                 className={textLogoClasses} 
             >
                 America's Excellent <br/> Credit Union
             </Typography>
        </div>
    )
}

export const ImgLogo = props => {
    const classes = useStyles();
    let overrideClass = props.overrideClass;
    return (
        <div className={classes.container}>
            <img src={props.src} className={overrideClass ? classes[overrideClass] : classes.logo} /> 
        </div>
    )
}

const CustomAppBar = props => {

    const classes = useStyles();
    let authData = useSelector((state) => state?.authData);
    let logoUrl = authData && authData.organization && authData.organization.image_url;
    return (
        <AppBar 
            {...props}
            className={classes.appbar} 
            userMenu={<CustomUserMenu />}
        >
            <div className={classes.container}>
                {
                    //<img src="https://cu-dev-uploads.s3.us-east-2.amazonaws.com/staging/org4.png" className={classes.logo} />
                    // <TextLogo />
                }
                <ImgLogo src={logoUrl} />
                <span className={classes.spacer} />
            </div>
        </AppBar>
    );
};

export default CustomAppBar;