import React from "react";
import {
  BooleanInput,
  SimpleForm,
  Create,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  ReferenceManyField,
  ReferenceField,
  TextField,
  required,
  useDataProvider, Loading, Error,
  RichTextField,
  RadioButtonGroupInput,
  List,
  Toolbar,
  useNotify,
  useRedirect,
  SaveButton,
  Datagrid,
  useQuery
} from 'react-admin';
import { useStore, useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import { BallotQuestionsGrid, BallotResponseGrid} from '../ballots/BallotShow';
import LoadingWithLogout from '../shared/LoadingWithLogout';

import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    titleClass: {
      fontSize: '30pt',
      textAlign: 'center',
      fontWeight: '600',
    },
    formWidth: {
      width: '1000px'
    }
});


const getBallotIDFromProps = (props) => {
  const ballotParamsFromLocation = props && props.location && props.location.search;
  const ballotParamsPassedIn = props && props.ballotID;
  if (ballotParamsFromLocation && ballotParamsFromLocation.includes('ballot_id')) {
    const splitParams = ballotParamsFromLocation.split("=");
    return splitParams[splitParams.length - 1]
  } else if (ballotParamsPassedIn) {
    return ballotParamsPassedIn
  }
  return null;
}


const getRedirectPathFromProps = (props) => {
  const redirectPathFromLocation = props && props.location && props.location.search;
  const redirectPathPassedIn = props && props.redirectPath;
  if (redirectPathFromLocation && redirectPathFromLocation.includes('basePath')) {
    const splitParams = redirectPathFromLocation.split("=");
    const path = splitParams[1].split("&")[0]
    return path
  } else if (redirectPathPassedIn) {
    return redirectPathPassedIn
  }
  return null;
}

const SubmitVoteToolbar = props => {
  return <Toolbar {...props} >
    <SaveButton
        label="Submit Vote"
        // redirect={props.redirect}
        submitOnEnter={false}
        color="secondary"
    />
  </Toolbar>
};


const VoteCreate = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const store = useStore();
  const state = store.getState();
  let memberProfileID = state && state.authData && state.authData.id;
  let orgID = state && state.authData && state.authData.organization && state.authData.organization.id;
  const votedForBallotIDs = useSelector((state) => state.authData.voted_on_ids.ballot_ids);
  const votedForBallotResponseIDs = useSelector((state) => state.authData.voted_on_ids.ballot_response_ids);

  const ballotID = getBallotIDFromProps(props);
  const redirectPath = getRedirectPathFromProps(props)
  const ballotQuestionsResp = useQuery({ 
      type: 'getList',
      resource: 'ballot_questions',
      payload: {
      	pagination: {page: 1, perPage: 100}, 
      	sort: {field: 'id', order: 'ASC'},
      	filter: {ballot_id: ballotID}
      },
  });
  const ballotQuestions = ballotQuestionsResp && ballotQuestionsResp.data;

  const dataProvider = useDataProvider();
  const [ballot, setBallot] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  React.useEffect(() => {
      dataProvider.getOne('ballots', { id: ballotID })
          .then(({ data }) => {
              setBallot(data);
              setLoading(false);
          })
          .catch(error => {
              setError(error);
              setLoading(false);
          })
  }, []);

  if (loading) return <LoadingWithLogout />;
  if (error) return <Error />;
  if (!ballot) return null;
  if (!ballotQuestions) return null;
  if (ballot && ballot.organization_id && (ballot.organization_id !== orgID)) return null;

  //organize ballot questions data 
  const ballotQuestionsData = {};
  for (let i=0; i<ballotQuestions.length; i++) {
  	let bq = ballotQuestions[i];
  	ballotQuestionsData[bq.id] = bq
  }
  const ballotQuestionIds = ballotQuestions.map(question => question.id);

  //create log 
  dataProvider.create('logs', { data: {member_profile: memberProfileID, organization: orgID, ballot: ballotID} })


  const onSuccess = (data) => {
    dispatch({"type": "AUTH_RECEIVED", "payload": {"data": data?.data}});
    notify("Congrats! You successfully voted.");
    redirect(redirectPath);
  }

  const onFailure = (error) => {
    notify('Vote failed. Try again.' || 'ra.notification.http_error', 'warning');
  };

  return (
      <Create {...props} onSuccess={onSuccess}>
        <SimpleForm defaultValue={{ballot: ballotID, organization: orgID, member_profile: memberProfileID}} className={classes.formWidth} toolbar={<SubmitVoteToolbar />}>
          <TextField record={ballot} source="title" label="" className={classes.titleClass} />
          <Divider fullWidth />
          <RichTextField record={ballot} source="content" label="" />
          <React.Fragment>
          	<BallotQuestionsGrid 
          		basePath={'/ballot_questions'}
          		ids={ballotQuestionIds}
          		data={ballotQuestionsData}
                ballot={ballot} 
                voteable={votedForBallotIDs && ballot && !votedForBallotIDs.includes(ballot.id)} 
                votedForBallotResponseIds={votedForBallotResponseIDs} 
            />
          </React.Fragment>
        </SimpleForm>
      </Create>
  )
};
export default VoteCreate